import Link from "next/link";
import { get } from "lodash";
import EnhancedEcommerce from "grandus-lib/utils/ecommerce";
import TagManager from "grandus-lib/utils/gtag";
import { scrollToTop } from "grandus-lib/utils";

import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

import Image from "grandus-lib/components-atomic/image/Image";
import Price from "grandus-lib/components-atomic/price/Price";

import ProductLabels from "components/product/labels/ProductLabels";

import styles from "components/product/card/ProductCard.module.scss";
import { useRouter } from "next/router";

const ProductCard = ({ options = {}, ...product }) => {
  const {
    name,
    urlTitle,
    brand,
    storeStatus,
    standardPriceData,
    finalPriceData,
    photo,
    status,
    discount,
    new: newattr,
    favourite,
    kind,
    labels,
    productHooks,
    store,
  } = product;
  const router = useRouter();
  const onClickHandler = (e) => {
    e.preventDefault();
    TagManager.push(
      EnhancedEcommerce.productClick(
        { ...product, ...{ position: get(options, "eecProduct.position") } },
        "category",
        {
          page: get(options, "eecProduct.page"),
          perPage: get(options, "eecProduct.perPage"),
        }
      ),
      () => {
        router
          .push("/produkt/[id]", `/produkt/${urlTitle}`)
          .then(() => scrollToTop());
      }
    );
  };
  return (
    <div className={styles.productCard}>
      <div className={styles.content}>
        <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
          <a onClick={onClickHandler}>
            <div className={styles.imgWrapper}>
              <Image photo={photo} size={"325x240"} type={"jpg"} usePlacehoder={true} />
              <ProductLabels
                {...{
                  urlTitle,
                  discount,
                  new: newattr,
                  status,
                  favourite,
                  labels,
                  productHooks,
                  standardPriceData,
                  finalPriceData
                }}
              />
            </div>
          </a>
        </Link>
        <div className={styles.details}>
          <div className={styles.brand}>{brand?.name}</div>
          <div className={styles.name}>{name}</div>
          <div className={styles.availability}>
            {product?.storeStatus?.available ? (
              <span className={`${styles.available}`}>
                <CheckCircleOutlined />{" "}
                {get(product, "storeStatus.message", "Skladom")}
              </span>
            ) : (
              <span className={`${styles.unavailable}`}>
                <CloseCircleOutlined />{" "}
                {get(product, "storeStatus.message", "Na objednávku")}
              </span>
            )}
          </div>
          <div className={styles.bottom}>
            <Price priceData={finalPriceData} className={styles.price} />
            <Link href="/produkt/[id]" as={`/produkt/${urlTitle}`}>
              <a onClick={onClickHandler}>
                <Button type={`primary`}>detail</Button>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
