import styles from "components/blog/card/BlogCard.module.scss";
import { Card } from "antd";

import dynamic from "next/dynamic";
const Link = dynamic(() => import("next/link"));
const Image = dynamic(() =>
  import("grandus-lib/components-atomic/image/Image")
);

const BlogCard = ({ title, urlTitle, perex, photo }) => {
  return (
    <div className={styles.wrapper}>
      <Link href="/blog/[id]" as={`/blog/${urlTitle}`}>
        <a>
          <Card
            cover={
              <Image photo={photo} size={"473x150__cropped"} type={"jpg"} />
            }
          >
            <Card.Meta
              title={title}
              description={
                <div
                  dangerouslySetInnerHTML={{
                    __html: perex,
                  }}
                />
              }
            />
          </Card>
        </a>
      </Link>
    </div>
  );
};

export default BlogCard;
